import { ProductFilterOption, ProductLookup } from '../types';

export const PRODUCT_FILTER_LABEL = 'Product';
export const COMPETITOR_FILTER_LABEL = 'Competitor';
export const SELECT_ALL_FILTER = 'all';
export const PAGE_TYPE_COMPARE = 'compare';

export const PRODUCT_FILTER_OPTIONS: ProductFilterOption[] = [
  {
    label: `All Products`,
    value: 'all',
    disabled: false,
  },
  {
    label: 'Sprinklr Service',
    value: 'care',
  },
  {
    label: 'Sprinklr Insights',
    value: 'research',
  },
  {
    label: 'Sprinklr Marketing',
    value: 'marketing',
  },
  {
    label: 'Sprinklr Social',
    value: 'sales',
  },
  {
    label: 'Platform',
    value: 'platform',
  },
];

export const PRODUCT_LOOKUP: ProductLookup = {
  care: 'Care',
  marketing: 'Marketing & Advertising',
  research: 'Research',
  platform: 'Platform',
  sales: 'Engagement',
};
