import { CompetitorTypeFilterOption } from '../types';

export const getCompetitorFilterOptions = (
  allCompetitors,
): CompetitorTypeFilterOption[] => {
  const options: CompetitorTypeFilterOption[] = [
    {
      label: 'All Competitors',
      value: 'all',
    },
  ];

  allCompetitors.forEach(competitor => {
    options.push({
      label: competitor,
      value: competitor,
    });
  });

  return options;
};
