import React, { useEffect, useMemo, useState } from 'react';
import { ThemeUICSSObject } from 'theme-ui';

import { CardFullDisplay } from '@sprinklr/shared-lib';
import { transformCompetitorCards } from './utils/transformCompetitorCards';
import { getAllUniqueCompetitors } from './utils/getAllCompetitors';
import { getCompetitorFilterOptions } from './utils/getCompetitorFilterOptions';

import { CompetitorGridTemplateProps } from './types';
import {
  PRODUCT_FILTER_OPTIONS,
  PRODUCT_LOOKUP,
  PRODUCT_FILTER_LABEL,
  COMPETITOR_FILTER_LABEL,
  SELECT_ALL_FILTER,
  PAGE_TYPE_COMPARE,
} from './constants/filters';

const CARD_FULL_DISPLAY_STYLES: ThemeUICSSObject = {
  pt: ['40px', 0, 0, '40px'],
};

const CompetitorGridTemplate = (props: CompetitorGridTemplateProps) => {
  let { competitorCards } = props;

  const transformedCompetitorCards = useMemo(
    () => transformCompetitorCards(competitorCards),
    [competitorCards],
  );
  const competitors = useMemo(
    () => getAllUniqueCompetitors(competitorCards),
    [competitorCards],
  );

  const [productFilter, setProductFilter] = useState(SELECT_ALL_FILTER);
  const [competitorFilter, setCompetitorFilter] = useState(SELECT_ALL_FILTER);
  const [filteredCompetitorCards, setFilteredCompetitorCards] = useState(
    transformedCompetitorCards,
  );

  useEffect(() => {
    const productFilteredCards =
      productFilter === SELECT_ALL_FILTER
        ? transformedCompetitorCards
        : transformedCompetitorCards.filter(
            card =>
              card.page.productCategory &&
              card.page.productCategory.includes(PRODUCT_LOOKUP[productFilter]),
          );
    const competitorFilteredCards =
      competitorFilter === SELECT_ALL_FILTER
        ? productFilteredCards
        : productFilteredCards.filter(
            card =>
              card.competitorName && card.competitorName === competitorFilter,
          );

    setFilteredCompetitorCards(competitorFilteredCards);
  }, [productFilter, competitorFilter, transformedCompetitorCards]);

  const competitorTypeFilterOptions = useMemo(() => {
    return getCompetitorFilterOptions(competitors);
  }, [competitors]);

  const filterDetails = useMemo(
    () => [
      {
        customDropdownLabel: PRODUCT_FILTER_LABEL,
        options: PRODUCT_FILTER_OPTIONS,
        value: productFilter,
        setValue: setProductFilter,
      },
      {
        customDropdownLabel: COMPETITOR_FILTER_LABEL,
        options: competitorTypeFilterOptions,
        value: competitorFilter,
        setValue: setCompetitorFilter,
      },
    ],
    [productFilter, competitorFilter],
  );

  return (
    <CardFullDisplay
      fullHeight={true}
      overallPageType={PAGE_TYPE_COMPARE}
      filterDetails={filterDetails}
      cards={filteredCompetitorCards}
      shouldHideNoResultsMessage={!competitorCards?.length && true}
      cardfullDisplaySx={CARD_FULL_DISPLAY_STYLES}
    />
  );
};

export default CompetitorGridTemplate;
