import { getPageSlug } from '@sprinklr/shared-lib/utils';
import { localizePath } from '@sprinklr/shared-lib/utils/localizePath';

import { CompetitorCard, TransformedCompetitorCard } from '../types';

export const transformCompetitorCards = (
  competitorCards: CompetitorCard[],
): TransformedCompetitorCard[] => {
  const transformedCompetitorCards = competitorCards.map(competitorCard => {
    const { page, image, competitorName } = competitorCard;
    const { node_locale, navSlug: slug, categorySlug, productCategory } = page;

    const actualSlug = getPageSlug({ categorySlug, slug, productCategory });
    const fullPath = localizePath(node_locale, actualSlug);

    return {
      ...competitorCard,
      title: page.pageCardTitle,
      description: page?.pageCardDescription,
      previewImage: image,
      slug: page.navSlug,
      languagesToRenderIn: page.translations.languagesToRenderIn,
      node_locale: page.node_locale,
      link: fullPath,
      competitorLabel: competitorName,
    };
  });
  return transformedCompetitorCards;
};
